<template>
  <div class="d-flex full-height" style="min-width: min-content">
    <div class="ant-row-items pos-rel ant-border-right">
      <panel-resizable
        class="d-flex flex-1 overflow-hidden ant-glass-background radius-0"
        side="left"
        :min-width="100"
        :default-width="400"
        :collapsible="false"
      >
        <div class="ant-border-bottom">
          <v-subheader class="px-6">
            <v-menu offset-y>
              <template #activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-icon class="mr-2" small v-on="{ ...tooltip, ...menu }"
                      >{{ headerStateIcon }}
                    </v-icon>
                  </template>
                  <span>Change calendar type</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item-group v-model="selectedHeader" color="primary">
                  <v-list-item v-for="(item, i) in headerOptions" :key="i">
                    <v-list-item-icon class="mr-2">
                      <v-icon small>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            Tasks
            <v-spacer />
            <slot name="ant-gantt-actions" />
          </v-subheader>
        </div>
        <div
          id="antRowHeaders"
          ref="ant-row-headers"
          class="flex-1 d-flex flex-column overflow-hidden"
        >
          <slot name="task-header-items" />
        </div>
      </panel-resizable>
    </div>
    <div class="d-flex flex-column">
      <div
        class="overflow-x-auto d-flex ant-border-bottom ant-glass-background radius-0 overflow-y-hidden"
      >
        <div
          v-for="(count, index) in planningCount"
          :key="count"
          :style="{ width: `${getHeaderWidth(index)}px` }"
          class="d-flex align-center justify-center ant-border-right"
        >
          <v-subheader>
            {{
              planningStart.clone().add(index, headerState).format(headerFormat)
            }}
          </v-subheader>
        </div>
      </div>
      <div
        ref="ant-row-items"
        class="d-flex flex-column flex-1 ant-border-right overflow-y-auto overflow-x-hidden"
      >
        <div
          v-if="tasks.length === 0"
          style="top: 50px"
          class="d-flex align-center justify-center font-italic full-height"
        >
          No tasks found
        </div>
        <div class="pos-rel">
          <div
            class="pos-abs full-height"
            :style="{
              left: `${getTodayOffset(now)}px`,
              width: `${dayWidth}px`,
              'background-color': `white`,
            }"
          ></div>
          <div
            v-for="date in vacationDays"
            :key="date.id"
            class="pos-abs full-height"
            :style="{
              left: `${getTodayOffset(date.date)}px`,
              width: `${dayWidth}px`,
              'background-color': `lightgrey`,
            }"
          ></div>
          <div
            v-for="(header, index) in headers"
            :key="`row-chart-header-${index}`"
            class="d-flex align-center"
            style="height: 36px; border-bottom: solid 1px lightgray"
          >
            <v-tooltip
              v-for="task in getHeaderTasks(header)"
              :key="task.id"
              bottom
            >
              <template #activator="{ on, attrs }">
                <div
                  class="ant-row-task"
                  :style="{
                    transform: `translateX(${getTaskOffset(task)}px)`,
                    width: `${getTaskWidth(task)}px`,
                    background: `${getTaskColor(task)}`,
                    opacity: `${highlightedItems.includes(task.id) || highlightedItems.length === 0 ? 1 : 0.5}`,
                    border: `${highlightedItems.includes(task.id) ? 'dotted 3px white' : ''}`,
                  }"
                  v-on="on"
                ></div>
              </template>
              <span>{{ task.title }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelResizable from '@/components/Project/PanelResizable.vue';
import {
  getChartEndDate,
  getChartHeaderDayWidth,
  getChartHeaderFormat,
  getChartHeaderIcon,
  getChartHeaderWidth,
  getChartStartDate,
  getChartTaskColor,
  getChartTaskOffset,
  getChartTaskWidth,
} from '@/components/Charts/utils/tasks-chart.utils';
import moment from 'moment/moment';

export default {
  name: 'TasksRowChart',
  components: { PanelResizable },
  props: {
    highlightedItems: {
      type: Array,
      default: () => [],
    },
    tasks: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    getHeaderTasks: {
      type: Function,
      required: true,
    },
    vacationDays: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      dayWidth: 49,
      headerOptions: [
        {
          icon: 'mdi-calendar-month',
          text: 'month',
        },
        {
          icon: 'mdi-calendar-week',
          text: 'week',
        },
        {
          icon: 'mdi-calendar-today',
          text: 'day',
        },
      ],
      selectedHeader: 1,
      headerState: 'week',
      planningStart: moment(),
      planningEnd: moment(),
      now: moment(),
    };
  },
  computed: {
    headerStateIcon() {
      return getChartHeaderIcon(this.headerState);
    },
    headerFormat() {
      return getChartHeaderFormat(this.headerState);
    },
    planningCount() {
      return Math.ceil(
        this.planningEnd.diff(this.planningStart, `${this.headerState}s`, true)
      );
    },
  },
  watch: {
    tasks() {
      this.planningStart = this.calculatePlanningStart();
      this.planningEnd = this.calculatePlanningEnd();
    },
    selectedHeader(value) {
      this.headerState = this.headerOptions[value].text;
    },
    headerState(value) {
      this.dayWidth = getChartHeaderDayWidth(value);
    },
  },
  mounted() {
    this.planningStart = this.calculatePlanningStart();
    this.planningEnd = this.calculatePlanningEnd();
    this.$refs['ant-row-items'].addEventListener(
      'scroll',
      this.handleScrollEvent
    );
  },
  beforeDestroy() {
    this.$refs['ant-row-items'].removeEventListener(
      'scroll',
      this.handleScrollEvent
    );
  },
  methods: {
    handleScrollEvent() {
      this.$refs['ant-row-headers'].scrollTop =
        this.$refs['ant-row-items'].scrollTop;
    },
    calculatePlanningStart() {
      return getChartStartDate(this.tasks, this.headerState);
    },
    calculatePlanningEnd() {
      return getChartEndDate(this.tasks, this.headerState);
    },
    getHeaderWidth(index) {
      return getChartHeaderWidth(
        this.headerState,
        this.dayWidth,
        this.planningStart,
        index
      );
    },
    getTodayOffset(date) {
      let days = moment(date).diff(this.planningStart, 'days');
      return days * this.dayWidth;
    },
    getTaskColor(task) {
      return getChartTaskColor(task);
    },
    getTaskWidth(task) {
      return getChartTaskWidth(task, this.dayWidth);
    },
    getTaskOffset(task) {
      return getChartTaskOffset(task, this.dayWidth, this.planningStart);
    },
  },
};
</script>

<style scoped lang="scss">
.ant-row-items {
  display: flex;
  position: sticky;
  left: 0;
  justify-self: flex-start;
  background-color: #e6e9f0;
  z-index: 10;
}

.ant-row-task {
  position: absolute;
  width: 5px;
  height: 24px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 3px;
  z-index: 2;
}

#antRowHeaders::-webkit-scrollbar {
  display: none;
}
</style>
